import Chart from 'chart.js/auto';
import 'chartjs-adapter-dayjs-4/dist/chartjs-adapter-dayjs-4.esm';

var ctx = document.getElementById("chart")
const daily_counts = releases_daily_data.map(item => { return {x: item.date, y: item.count }});
const weekly_avg = releases_weekly_avg_data.map(item => {return {x: item.date, y: Math.round(item.avg)}});
const chart = new Chart(ctx, {    
    data: {        
        datasets: [{
            type: 'line',
            showLine: false,
            label: 'Daily Releases',
            data: daily_counts,
            pointBackgroundColor: '#9dc5e0',
            pointBorderColor: '#9dc5e0',
            fill: false
        },
        {
            tension: 0.2,
            type: 'line',
            label: 'Weekly Average',
            data: weekly_avg,
            fill: false
        }    
        ]
    },
    options: {
        plugins: {
            legend: {
                labels: {
                    color: "#9dc5e0",
                },
            },
        },        
        scales: {
            x: {
                ticks: { color: "#9dc5e0", beginAtZero: true },
                type: 'time',
                time: {
                    unit: 'month'
                }
            },
            y: {
                ticks: { color: "#9dc5e0", beginAtZero: true },
            }            
        }
    }
});        